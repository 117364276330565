<template>
<!-- 公司介绍 -->
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/1.jpg" >
    </div>
    <div class="module-content">
      <div class="nuox-breadcrumb">
        <span class="nuox-breadcrumb-item"><router-link tag="span" :to="$t('lang.headerMenus[0].pageSrc')">{{ $t("lang.headerMenus[0].name") }}</router-link></span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[1].name") }}</span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[1].childrens[0].name")}}</span>
      </div>
      <div class="module-line"></div>
        <div :class="$i18n.locale=='en'?'module-nav-en':'module-nav'">
          <ul class="menu-vertical-nav">
            <li v-for="(item,index) in menus" :key="index" @click="checknav(item)">
              <div :class="'title '+[item.id==checkId?'open':'']" ><span>{{item.name}}</span><span v-if="item.childrens&&item.childrens.length>0"  :class="[item.id==checkId?'el-icon-arrow-down':'el-icon-arrow-right']+ ' icon-down'"></span></div>
              <dl v-if="item.childrens && item.id==checkId && item.childrens.length>0">
                <dd :class="[item2.id==checkChildrenId?'in':'']" v-for="(item2,index2) in item.childrens" :key="index2" @click="checknavchildren(item,item2)">{{item2.name}}</dd>
              </dl>
            </li>
          </ul>
        </div>
        <div class="nuox-module">
          <div class="nuox-content-title">
            <div class="title-name">
              {{ $t("lang.headerMenus[1].childrens[0].name") }}
              <div class="xian"></div>
            </div>
          </div>
          <div v-if="$i18n.locale=='en'"  class="article-content" v-html="companyJieShaoEn"></div>
          <div v-if="$i18n.locale=='zh'"  class="article-content" v-html="companyJieShaoZh"></div>
          <div class="module-line"></div>
          <div class="nuox-title-two" style="margin-top:50px;margin-bottom:25px">
                  <i class="iconfont icon-shuxian"></i> {{ $t("lang.headerMenus[1].childrens[0].childrens[0].name")}}<i class="iconfont icon-shuxian"></i>
          </div>
          <div v-if="$i18n.locale=='zh'"  class="article-content" v-html="jiazhizhuzhangZh" style="text-indent:25px"></div>
          <div v-if="$i18n.locale=='en'"  class="article-content" v-html="jiazhizhuzhangEn"></div>
          <div class="module-line"></div>
          <div class="nuox-title-two" style="margin-top:50px;margin-bottom:25px">
                  <i class="iconfont icon-shuxian"></i> {{ $t("lang.headerMenus[1].childrens[0].childrens[1].name")}}<i class="iconfont icon-shuxian"></i>
          </div>
          <div>
            <div v-if="$i18n.locale=='zh'" v-html="fuwubankuaiZh"></div>
            <div v-if="$i18n.locale=='en'" v-html="fuwubankuaiEn"></div>
           <!--
             <el-row class="fuwubankuai-two">
              <el-col :span="5">
                <div class="fuwubankuai-item">
                  <img src="http://39.104.95.82:8087/img/about/7.jpg">
                </div>
              </el-col>
              <el-col :span="5">
                <div class="fuwubankuai-item">
                  <img src="http://39.104.95.82:8087/img/about/8.jpg">
                </div>
              </el-col>
              <el-col :span="10">
                <div class="fuwubankuai-item">
                  <div class="fuwubankuai-content">
                      <div class="fuwubankuai-text">诺兴优创是一家以生物科技、医药工程、国际出版、知识产权、国际科研为主体服务发展的公司，形成了五位一体相互支撑，相互促进的发展体系，为客户提供高效，优质专业的科技服务。</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="fuwubankuai-item" style="padding:0">
                  <img src="http://39.104.95.82:8087/img/about/9.jpg">
                </div>
              </el-col>
             </el-row>
             -->
          </div>
          <div class="module-line"></div>
          <div class="nuox-title-two" style="margin-top:50px;margin-bottom:25px">
                  <i class="iconfont icon-shuxian"></i> {{ $t("lang.headerMenus[1].childrens[0].childrens[2].name")}}<i class="iconfont icon-shuxian"></i>
          </div>
           <div v-if="$i18n.locale=='zh'"  class="article-content" v-html="zhanlvhezuoZh" style="text-indent:25px"></div>
           <div v-if="$i18n.locale=='en'"  class="article-content" v-html="zhanlvhezuoEn"></div>
          <div class="module-line"></div>
          <div class="nuox-title-two" style="margin-top:50px;margin-bottom:25px">
                  <i class="iconfont icon-shuxian"></i> {{ $t("lang.headerMenus[1].childrens[0].childrens[3].name")}}<i class="iconfont icon-shuxian"></i>
          </div>
           <div class="layout-text-info" v-if="$i18n.locale=='zh'" v-html="bujumiaoshuZh" style="text-indent:25px"></div>
            <div class="layout-text-info" v-if="$i18n.locale=='en'" v-html="bujumiaoshuEn" style="text-indent:25px"></div>
             <div id="china_map_box">
                <div id="china_map" style="height:500px;width:100%"></div>
            </div>
        </div>
        <div class="module-line"></div>
    </div>
  </div>
</template>
<script>
 import china from "echarts/map/json/china.json";  //中国地图

export default {
  computed:{
    menus:function(){
      return this.$t('lang.headerMenus[1].childrens');
    }
  },
  data:function() {
    return {
        checkId:8,
        checkChildrenId:0,
        companyJieShaoEn:'',
        companyJieShaoZh:'',
        jiazhizhuzhangZh:'',
        jiazhizhuzhangEn:'',
        fuwubankuaiZh:'',
        fuwubankuaiEn:'',
        zhanlvhezuoZh:'',
        zhanlvhezuoEn:'',
        checkChildrenId:0,
        bujumiaoshuZh:'',
        bujumiaoshuEn:'',
        pageNum:1,
        pageCount:30,
        geoCoordMap:{
    '海门':[121.15,31.89],
    '鄂尔多斯':[109.781327,39.608266],
    '招远':[120.38,37.35],
    '舟山':[122.207216,29.985295],
    '齐齐哈尔':[123.97,47.33],
    '盐城':[120.13,33.38],
    '赤峰':[118.87,42.28],
    '青岛':[120.33,36.07],
    '乳山':[121.52,36.89],
    '金昌':[102.188043,38.520089],
    '泉州':[118.58,24.93],
    '莱西':[120.53,36.86],
    '日照':[119.46,35.42],
    '胶南':[119.97,35.88],
    '南通':[121.05,32.08],
    '拉萨':[91.11,29.97],
    '云浮':[112.02,22.93],
    '梅州':[116.1,24.55],
    '文登':[122.05,37.2],
    '上海':[121.48,31.22],
    '攀枝花':[101.718637,26.582347],
    '威海':[122.1,37.5],
    '承德':[117.93,40.97],
    '厦门':[118.1,24.46],
    '汕尾':[115.375279,22.786211],
    '潮州':[116.63,23.68],
    '丹东':[124.37,40.13],
    '太仓':[121.1,31.45],
    '曲靖':[103.79,25.51],
    '烟台':[121.39,37.52],
    '福州':[119.3,26.08],
    '瓦房店':[121.979603,39.627114],
    '即墨':[120.45,36.38],
    '抚顺':[123.97,41.97],
    '玉溪':[102.52,24.35],
    '张家口':[114.87,40.82],
    '阳泉':[113.57,37.85],
    '莱州':[119.942327,37.177017],
    '湖州':[120.1,30.86],
    '汕头':[116.69,23.39],
    '昆山':[120.95,31.39],
    '宁波':[121.56,29.86],
    '湛江':[110.359377,21.270708],
    '揭阳':[116.35,23.55],
    '荣成':[122.41,37.16],
    '连云港':[119.16,34.59],
    '葫芦岛':[120.836932,40.711052],
    '常熟':[120.74,31.64],
    '东莞':[113.75,23.04],
    '河源':[114.68,23.73],
    '淮安':[119.15,33.5],
    '泰州':[119.9,32.49],
    '南宁':[108.33,22.84],
    '营口':[122.18,40.65],
    '惠州':[114.4,23.09],
    '江阴':[120.26,31.91],
    '蓬莱':[120.75,37.8],
    '韶关':[113.62,24.84],
    '嘉峪关':[98.289152,39.77313],
    '广州':[113.23,23.16],
    '延安':[109.47,36.6],
    '太原':[112.53,37.87],
    '清远':[113.01,23.7],
    '中山':[113.38,22.52],
    '昆明':[102.73,25.04],
    '寿光':[118.73,36.86],
    '盘锦':[122.070714,41.119997],
    '长治':[113.08,36.18],
    '深圳':[114.07,22.62],
    '珠海':[113.52,22.3],
    '宿迁':[118.3,33.96],
    '咸阳':[108.72,34.36],
    '铜川':[109.11,35.09],
    '平度':[119.97,36.77],
    '佛山':[113.11,23.05],
    '海口':[110.35,20.02],
    '江门':[113.06,22.61],
    '章丘':[117.53,36.72],
    '肇庆':[112.44,23.05],
    '大连':[121.62,38.92],
    '临汾':[111.5,36.08],
    '吴江':[120.63,31.16],
    '石嘴山':[106.39,39.04],
    '沈阳':[123.38,41.8],
    '苏州':[120.62,31.32],
    '茂名':[110.88,21.68],
    '嘉兴':[120.76,30.77],
    '长春':[125.35,43.88],
    '胶州':[120.03336,36.264622],
    '银川':[106.27,38.47],
    '张家港':[120.555821,31.875428],
    '三门峡':[111.19,34.76],
    '锦州':[121.15,41.13],
    '南昌':[115.89,28.68],
    '柳州':[109.4,24.33],
    '三亚':[109.511909,18.252847],
    '自贡':[104.778442,29.33903],
    '吉林':[126.57,43.87],
    '阳江':[111.95,21.85],
    '泸州':[105.39,28.91],
    '西宁':[101.74,36.56],
    '宜宾':[104.56,29.77],
    '呼和浩特':[111.65,40.82],
    '成都':[104.06,30.67],
    '大同':[113.3,40.12],
    '镇江':[119.44,32.2],
    '桂林':[110.28,25.29],
    '张家界':[110.479191,29.117096],
    '宜兴':[119.82,31.36],
    '北海':[109.12,21.49],
    '西安':[108.95,34.27],
    '金坛':[119.56,31.74],
    '东营':[118.49,37.46],
    '牡丹江':[129.58,44.6],
    '遵义':[106.9,27.7],
    '绍兴':[120.58,30.01],
    '扬州':[119.42,32.39],
    '常州':[119.95,31.79],
    '潍坊':[119.1,36.62],
    '重庆':[106.54,29.59],
    '台州':[121.420757,28.656386],
    '南京':[118.78,32.04],
    '滨州':[118.03,37.36],
    '贵阳':[106.71,26.57],
    '无锡':[120.29,31.59],
    '本溪':[123.73,41.3],
    '克拉玛依':[84.77,45.59],
    '渭南':[109.5,34.52],
    '马鞍山':[118.48,31.56],
    '宝鸡':[107.15,34.38],
    '焦作':[113.21,35.24],
    '句容':[119.16,31.95],
    '北京':[116.46,39.92],
    '徐州':[117.2,34.26],
    '衡水':[115.72,37.72],
    '包头':[110,40.58],
    '绵阳':[104.73,31.48],
    '乌鲁木齐':[87.68,43.77],
    '枣庄':[117.57,34.86],
    '杭州':[120.19,30.26],
    '淄博':[118.05,36.78],
    '鞍山':[122.85,41.12],
    '溧阳':[119.48,31.43],
    '库尔勒':[86.06,41.68],
    '安阳':[114.35,36.1],
    '开封':[114.35,34.79],
    '济南':[117,36.65],
    '德阳':[104.37,31.13],
    '温州':[120.65,28.01],
    '九江':[115.97,29.71],
    '邯郸':[114.47,36.6],
    '临安':[119.72,30.23],
    '兰州':[103.73,36.03],
    '沧州':[116.83,38.33],
    '临沂':[118.35,35.05],
    '南充':[106.110698,30.837793],
    '天津':[117.2,39.13],
    '富阳':[119.95,30.07],
    '泰安':[117.13,36.18],
    '诸暨':[120.23,29.71],
    '郑州':[113.65,34.76],
    '哈尔滨':[126.63,45.75],
    '聊城':[115.97,36.45],
    '芜湖':[118.38,31.33],
    '唐山':[118.02,39.63],
    '平顶山':[113.29,33.75],
    '邢台':[114.48,37.05],
    '德州':[116.29,37.45],
    '济宁':[116.59,35.38],
    '荆州':[112.239741,30.335165],
    '宜昌':[111.3,30.7],
    '义乌':[120.06,29.32],
    '丽水':[119.92,28.45],
    '洛阳':[112.44,34.7],
    '秦皇岛':[119.57,39.95],
    '株洲':[113.16,27.83],
    '石家庄':[114.48,38.03],
    '莱芜':[117.67,36.19],
    '常德':[111.69,29.05],
    '保定':[115.48,38.85],
    '湘潭':[112.91,27.87],
    '金华':[119.64,29.12],
    '岳阳':[113.09,29.37],
    '长沙':[113,28.21],
    '衢州':[118.88,28.97],
    '廊坊':[116.7,39.53],
    '菏泽':[115.480656,35.23375],
    '合肥':[117.27,31.86],
    '武汉':[114.31,30.52],
    '大庆':[125.03,46.58]
}
    };
  },
  mounted(){
    this.$echarts.registerMap("china", china);
    this.init();
    this.zlbj();
    this.company();
    this.JZZZ();
    this.FWMK();
    this.ZLHZ();
  },
  methods:{
    checknav(e){
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
      this.checkId = e.id;
    },
    checknavchildren(item,item2){
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    },async company(){//加载公司介绍
      var that = this;
      const { data: res } = await this.$http.get(
        "webSite/about_company_profile"
      );
      if(res.code==0){
        var data = res.data.data;
        that.companyJieShaoEn= data.profileContentEn;
        that.companyJieShaoZh= data.profileContentZh;
      }
    },async JZZZ(){//加载价值主张
      var that = this;
      const { data: res } = await this.$http.get(
        "webSite/about_worth?worthType=JZZZ"
      );
      if(res.code==0){
        var data = res.data.data;
        if(data.length>0){
          that.jiazhizhuzhangEn= data[0].worthContentEn;
          that.jiazhizhuzhangZh= data[0].worthContentZh;
        }
      }
    },async FWMK(){//加载服务板块
      var that = this;
      const { data: res } = await this.$http.get(
        "webSite/about_worth?worthType=FWMK"
      );
      if(res.code==0){
        var data = res.data.data;
        if(data.length>0){
          that.fuwubankuaiEn= data[0].worthContentEn;
          that.fuwubankuaiZh= data[0].worthContentZh;
        }
      }
    },async ZLHZ(){//加载战略合作
      var that = this;
      const { data: res } = await this.$http.get(
        "webSite/about_worth?worthType=ZLHZ"
      );
      if(res.code==0){
        var data = res.data.data;
        if(data.length>0){
          that.zhanlvhezuoEn= data[0].worthContentEn;
          that.zhanlvhezuoZh= data[0].worthContentZh;
        }
      }
    },
     async init(){
      var locale =  localStorage.getItem('locale');
      var baseUrl = this.$baseURL;
      var that = this;
       var pageNum = this.pageNum;
      var pageCount = this.pageCount;
      const { data: res } = await this.$http.get(
        "webSite/nuox_subsidiary?pageNum="+pageNum+"&pageCount="+pageCount
      );
      if(res.code==0){
        var data = res.data.data;
        var mapData = [];
        for(var key in data){
          var d1 = {};
          d1.name = data[key].province?data[key].province.replaceAll('省',''):'';//省份
          d1.name = d1.name.replaceAll('市','');//省份
          d1.value = 0;
          d1.address = locale=='zh'?data[key].addressZh:data[key].addressEn;
          var attList = data[key].attList;
          var images = [];
          for(var index in attList){
            images.push(baseUrl+attList[index].attPath+attList[index].attRealName);
          }
          d1.images = images;
          mapData.push(d1);
        }
        that.setMapData(mapData,that.convertData(data));
      }
    },
    async zlbj(){//加载战略部署
      var that = this;
      const { data: res } = await this.$http.get(
        "webSite/about_zhanlv"
      );
      if(res.code==0){
        var data = res.data.data;
          that.bujumiaoshuEn= data.zhanlvContentEn;
          that.bujumiaoshuZh= data.zhanlvContentZh;
      }
    },
    convertData(data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
            var name = data[i].city.replaceAll('市','');
            if(data[i].province.indexOf("市")!=-1){
               var name1 = data[i].province.replaceAll('市','');
              var geoCoord = this.geoCoordMap[name1];
              if (geoCoord) {
                var par={}
                par.name = name;
                par.coord = geoCoord;
                par.symbol = 'image://http://39.104.95.82:8087/img/loc.png'
                par.symbolSize=30;
                  res.push(par);
              }
            }
            var geoCoord = this.geoCoordMap[name];
            if (geoCoord&&data[i].addressZh!=null&&data[i].addressZh!='') {
              var par={}
              par.name = name;
              par.coord = geoCoord;
              par.symbol = 'image://http://39.104.95.82:8087/img/loc.png'
              par.symbolSize=30;
                res.push(par);
            }
        }
        return res;
    },
    checknav(e){
      this.checkId = e.id;
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
    },
    checknavchildren(item,item2){
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    },
     // 地图
    setMapData(mapData,cityMap) {
      let  echarts= this.$echarts;
      // 初始化echarts实例 #D8E9FD
      this.chinachart = echarts.init(document.getElementById("china_map"));
      // 进行相关配置
      this.chartOption = {
        tooltip: {
          trigger: 'item',
          // 鼠标移到图里面的浮动提示框
          // formatter详细配置： https://echarts.baidu.com/option.html#tooltip.formatter
          formatter(params, ticket, callback) {
            // params.data 就是series配置项中的data数据遍历
            let localValue, address,img1,img2;
            
            if (params.data) {
              localValue = params.data.value;
              var images = params.data.images;
              if(!images||images.length==0){
                  return ``;
              }
              let htmlStr = `
                <div class='nuox-branch'>`;
              for(var k in images){
                img1 = images[k];
                htmlStr += `<img src='`+img1+`' alt=""> `;
              }
              address = params.data.address;
                htmlStr += `</div>
                  <div class='nuox-address'>
                  ${address}
                  </div>
                `;
              return htmlStr;
            } else {
              return ``;
            }
          },
          backgroundColor: "rgba(0,0,0,0)", //提示标签背景颜色
          //textStyle: { color: "#fff" }, //提示标签字体颜色
        },
        visualMap: {
          show: false,
          bottom: 60,
          right: 200,
          text: ["高", "低"],
          //  min:0,
          //   precision:0,
          itemHeight: 200,
          color: [
            "#75C4EF"
          ],
        },
        // geo配置详解： https://echarts.baidu.com/option.html#geo
        geo: {
          // 地理坐标系组件用于地图的绘制
          map: "china", // 表示中国地图
          name:'',
          // roam: true, // 是否开启鼠标缩放和平移漫游
          zoom: 1.2, // 当前视角的缩放比例（地图的放大比例）
          label: {
             show: false
          },
          itemStyle: {
            // 地图区域的多边形 图形样式。
            normal: {
              //未选中的状态
              borderColor: "#ffffff",
              areaColor: "#C1C1C1", //背景颜色
              label: {
                // show: true, //显示名称
              },
            },
            emphasis: {
              //选中的状态
              // 高亮状态下的多边形和标签样式
              shadowBlur: 20,
              shadowColor: "rgba(0, 0, 0, 0.5)",
              borderColor: "#fff",
              areaColor: "#017CC2",
              label: {
                 show: false, //显示名称
              },
            },
          },
        },
        series: [
          {
            name: "地图", // 浮动框的标题（上面的formatter自定义了提示框数据，所以这里可不写）
            type: "map",
            geoIndex: 0,
            label: {
              show: true,
            },
            // 这是需要配置地图上的某个地区的数据，根据后台的返回的数据进行拼接（下面是我定义的假数据）
            data: mapData,
             markPoint: { //图表标注。
                    label: {
                        normal: {
                            show: false,
                        },
                        emphasis: {
                            show: false,
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: 'rgba(72,150,128,1)'
                        }
                    },
                    data: cityMap
                }
          }
        ],
      };
      // 使用刚指定的配置项和数据显示地图数据
      this.chinachart.setOption(this.chartOption);
    },
  }
}
</script>
<style>
@import '../../assets/css/about.css';
.fuwubankuai-two img{
  height: 120px;
  width: 100%;
}
.fuwubankuai-two .fuwubankuai-item{
  height: 100%;
  padding-right: 10px;
  height: 120px;
}
.fuwubankuai-text{
  padding: 0 10px;
  text-indent:25px;
}
.fuwubankuai-two .el-col{
  height: 100%;
}
.fuwubankuai-content{
  background-color: #194492;
  color: #fff;
  letter-spacing: 3px;
  font-size: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nuox-module-culture{
  padding: 0 50px;
}
.nuox-branch {
  text-align: right;
}
.nuox-branch img{
  width: 100px;
  height: 60px;
}
.nuox-address{
  max-width: 400px;
  background-color: #017CC2;
  color: #fff;
  border-radius: 1px;
  padding: 5px 10px;
}
.layout-text-info{
  line-height: 30px;
  letter-spacing: 1px;
}
</style>